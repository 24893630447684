<template>
  <div class="contracts-mobile-list">
    <filled-mobile-list v-for="item in items" :key="item.id" class="mb-4">
      <template #header>
        <div class="pa-3 contracts-mobile-list__header-container d-flex align-center justify-space-between">
          <div>
            <div class="text-caption">{{ $t('contracts.status') }}</div>
            <div class="text-body-2 font-weight-medium" :class="{ 'primary--text': item.status === 'active' }">
              {{ item.status }}
            </div>
          </div>

          <div class="contracts-mobile-list__actions">
            <icon-button :width="36" class="contracts-mobile-list__action" :to="getEditRoute(item.id)">
              <v-icon>mdi-pencil</v-icon>
            </icon-button>

            <icon-button
              :width="36"
              :loading="getLoadingStatus(item.id)"
              class="contracts-mobile-list__action ml-2"
              @click="archive(item.id)"
            >
              <v-icon>mdi-archive</v-icon>
            </icon-button>
          </div>
        </div>
      </template>

      <view-list-field class="mb-3" inline :label="$t('contracts.project')">
        {{ item.projectName }}
      </view-list-field>

      <view-list-field class="mb-3" inline :label="$t('contracts.building')">
        {{ item.buildingName }}
      </view-list-field>

      <view-list-field class="mb-3" inline :label="$t('contracts.unit')">
        {{ item.unitName }}
      </view-list-field>

      <view-list-field class="mb-3" inline :label="$t('contracts.clients')">
        {{ item.client }}
      </view-list-field>

      <v-divider class="contracts-mobile-list__divider my-4" />

      <div class="d-flex align-center justify-space-between">
        <view-list-field :label="$t('contracts.periodContracts')">
          {{ item.periodDate }}
        </view-list-field>

        <div class="contracts-mobile-list__select d-flex text--secondary">
          {{ $t('contracts.select') }}
          <base-checkbox
            :input-value="getSelectedStatus(item)"
            class="contracts-mobile-list__checkbox ml-1 pt-0 mt-0"
            hide-details
            @change="select(item)"
          />
        </div>
      </div>
    </filled-mobile-list>
  </div>
</template>

<script>
// Components
import ViewListField from '@/components/ViewListField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import FilledMobileList from '@/components/FilledMobileList.vue';
import { CONTRACTS_DETAILED } from '@/constants/routes';
import IconButton from '@/components/IconButton.vue';

import { dateDoubleFormat } from '@/utils/dateFormatting';

export default {
  name: 'ContractsMobileList',

  components: { ViewListField, BaseCheckbox, FilledMobileList, IconButton },

  model: {
    prop: 'selectedItems',
    event: 'update:selectedItems',
  },

  props: {
    items: { type: Array, required: true },
    selectedItems: { type: Array, default: () => [] },
    loadingContracts: { type: Array, default: () => [] },
  },

  data() {
    return {
      isSelected: false,
    };
  },

  computed: {
    selectedItemsModel: {
      get() {
        return this.selectedItems;
      },

      set(value) {
        this.$emit('update:selectedItems', value);
      },
    },
  },

  methods: {
    getLoadingStatus(usageId) {
      const foundUsage = this.loadingContracts.find(id => id === usageId);

      return Boolean(foundUsage);
    },

    getSelectedStatus(item) {
      return this.selectedItemsModel.findIndex(usage => usage.id === item.id) !== -1;
    },

    select(item) {
      if (this.getSelectedStatus(item)) {
        const filteredValue = this.selectedItemsModel.filter(usage => usage.id !== item.id);

        this.selectedItemsModel = filteredValue;
        return;
      }

      this.selectedItemsModel = [...this.selectedItems, item];
    },

    archive(id) {
      this.$emit('archive', id);
    },

    getEditRoute(id) {
      return { name: CONTRACTS_DETAILED, params: { id } };
    },

    dateDoubleFormat,
  },
};
</script>

<style lang="scss">
.contracts-mobile-list {
  $action-button-width: 36px;

  &__action.secondary {
    border: 1px solid $--white-color-0 !important;
  }

  &__divider {
    margin-left: -8px;
    max-width: calc(100% + 16px);
    width: calc(100% + 16px);
    border-color: $--white-color-0 !important;
  }

  &__header-container {
    width: 100%;
  }
}
</style>
