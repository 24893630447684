<template>
  <div class="contracts" :class="{ 'contracts--archive': !isActiveStatus }">
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('contracts.back_to_contracts') }}</v-btn>
      <v-row v-else no-gutters>
        <v-col class="pr-2" cols="6">
          <v-btn class="font-weight-bold flex-grow-1" color="primary" block @click="selectAllInPage"
            >{{ $t('button.on_page') }} ({{ pageContractsCount }})</v-btn
          >
        </v-col>

        <v-col class="pl-2" cols="6">
          <v-btn class="font-weight-bold flex-grow-1" color="primary" block @click="selectGlobal"
            >{{ $t('button.all') }} ({{ contractsCount }})</v-btn
          >
        </v-col>
      </v-row>
    </info-modal>
    <entity-status-list :statuses="entityContractsStatuses">
      <template v-if="canDisplayActions">
        <v-btn v-if="isActiveStatus" color="secondary" class="primary--text" large @click="archiveContracts">
          {{ $t('button.archive') }}
          <v-icon right>mdi-archive-arrow-down</v-icon>
        </v-btn>
      </template>
    </entity-status-list>
    <contracts-missing-data v-if="isDataMissing" class="contracts__missing-data" :is-archive="!isActiveStatus" />
    <template v-else>
      <div v-if="media.isMobile" class="d-flex flex-column mb-1 mb-md-4">
        <mobile-sort
          class="mt-2"
          :reverse="canUpdateContracts"
          :sort-list="contractsHeaders"
          :sort-by="activeHeader"
          :options.sync="options"
        >
          <contracts-menu
            v-if="canUpdateContracts"
            :page="pageModel"
            :status="status"
            @archive-contracts="archiveContracts"
            @select-contracts="selectAll"
          />
        </mobile-sort>

        <mobile-search-menu
          :key="$route.name"
          v-model="queryModel"
          :placeholder="$t('label.contracts_search')"
          single-line
          full-width
          hide-details
        />
      </div>
      <div class="contracts__list">
        <list-loading v-if="isLoading" />
        <template v-else>
          <search-bar
            v-if="!media.isMobile"
            :key="$route.name"
            v-model="queryModel"
            class="mb-6"
            :placeholder="$t('label.contracts_search')"
            single-line
            full-width
            hide-details
          />
          <contracts-mobile-list
            v-if="media.isMobile"
            v-model="selected"
            :items="contracts"
            :loading-contracts="loadingContracts"
            class="mt-6"
            @archive="archiveUsage"
          />
          <base-table
            v-else
            v-model="selected"
            hide-default-footer
            :show-select="isActiveStatus"
            checkbox-color="primary"
            :items="contracts"
            :headers="contractsHeaders"
            :loading="tableLoading"
            :options.sync="options"
            :server-items-length="contractsLimit"
          >
            <template v-slot:[`header.data-table-select`]="{ props, on }">
              <v-menu
                v-if="!media.isMobile"
                v-model="dialogIsOpen"
                :close-on-content-click="false"
                offset-y
                :value="true"
              >
                <template #activator="{ on: modalActivator }">
                  <v-simple-checkbox
                    v-bind="props"
                    :ripple="false"
                    @click="selectAll(props, modalActivator)"
                    v-on="on"
                  />
                </template>
                <v-card max-width="352">
                  <v-card-text class="text-subtitle-2">
                    {{ $t('contracts.select_info') }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text class="font-weight-bold" color="primary" @click="selectAllInPage"
                      >{{ $t('button.all_in_page') }} ({{ pageContractsCount }})</v-btn
                    >
                    <v-btn text class="font-weight-bold" color="primary" @click="selectGlobal"
                      >{{ $t('button.all') }} ({{ contractsCount }})</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:item.client="{ item }">
              <router-link
                class="contracts__table-link"
                :to="{ name: $options.CLIENTS_DETAILED, params: { id: item.clientId } }"
              >
                {{ item.client }}
              </router-link>
            </template>
            <template v-slot:item.projectName="{ item }">
              <router-link
                class="contracts__table-link"
                :to="{ name: $options.PROJECTS_DETAILED, params: { id: item.projectId } }"
              >
                {{ item.projectName }}
              </router-link>
            </template>
            <template v-slot:item.buildingName="{ item }">
              <router-link
                class="contracts__table-link"
                :to="{ name: $options.BUILDINGS_DETAILED, params: { id: item.buildingId } }"
              >
                {{ item.buildingName }}
              </router-link>
            </template>
            <template v-slot:item.unitName="{ item }">
              <router-link
                class="contracts__table-link"
                :to="{ name: $options.UNITS_DETAILED, params: { id: item.unitId } }"
              >
                {{ item.unitName }}
              </router-link>
            </template>
            <template v-slot:item.clientType="{ item }">
              {{ $t(`client.${item.clientType}`) }}
            </template>
            <template v-slot:item.periodDate="{ item }">
              {{ dateDoubleFormat(item.periodDate) }}
            </template>
            <template v-slot:item.status="{ item }">
              <div class="d-flex align-center">
                {{ $t(`unit.contract_${item.status}`) }}

                <div v-if="displayActions" class="d-flex align-center flex-grow-1 justify-end">
                  <icon-button :width="36" :to="{ name: $options.CONTRACTS_DETAILED, params: { id: item.id } }">
                    <v-icon>mdi-pencil</v-icon>
                  </icon-button>

                  <icon-button
                    :width="36"
                    class="ml-2"
                    :loading="getLoadingStatus(item.id)"
                    @click="archiveUsage(item.id)"
                  >
                    <v-icon>mdi-archive</v-icon>
                  </icon-button>
                </div>
              </div>
            </template>
          </base-table>
        </template>
      </div>
      <base-pagination v-if="pageCount && !isLoading" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
import EntityStatusList from '@/components/EntityStatusList.vue';
import ContractsMenu from '@/components/Contracts/Menu.vue';
import ContractsMissingData from '@/components/Contracts/MissingData.vue';
import SearchBar from '@/components/SearchBar.vue';
import MobileSort from '@/components/MobileSort/index.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import ListLoading from '@/components/ListLoading.vue';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/BasePagination.vue';
import InfoModal from '@/components/InfoModal.vue';
import ContractsMobileList from '@/components/Contracts/MobileList.vue';
import IconButton from '@/components/IconButton.vue';

import {
  CONTRACTS,
  CONTRACTS_ARCHIVE,
  CONTRACTS_DETAILED,
  CLIENTS_DETAILED,
  PROJECTS_DETAILED,
  BUILDINGS_DETAILED,
  UNITS_DETAILED,
} from '@/constants/routes';
import { CONTRACTS as CONTRACTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';
import * as entityStatuses from '@/constants/entityStatuses';
import { CONTRACTS_LIST, ARCHIVE_CONTRACTS } from '@/constants/analyticsActions';

import { throttle } from '@/utils/delay';
import { flushPromises } from '@/utils/scheduler';
import { camelToSnake } from '@/utils/formatters';
import { dateDoubleFormat } from '@/utils/dateFormatting';

import client from '@/http/client';
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';

export default {
  CONTRACTS_DETAILED,
  CLIENTS_DETAILED,
  PROJECTS_DETAILED,
  BUILDINGS_DETAILED,
  UNITS_DETAILED,

  name: 'Contracts',
  components: {
    EntityStatusList,
    ContractsMenu,
    ContractsMissingData,
    SearchBar,
    MobileSort,
    MobileSearchMenu,
    ListLoading,
    BaseTable,
    BasePagination,
    InfoModal,
    ContractsMobileList,
    IconButton,
  },
  inject: ['media'],
  props: {
    status: { type: String, required: true },
  },
  data() {
    return {
      query: '',
      page: +this.$route.query.page || 1,
      isDataMissing: false,
      isLoading: false,
      options: { sortBy: '', sortDesc: null },
      contractsLimit: 10,
      pageCount: 1,
      contractsCount: 0,
      contracts: [],
      tableLoading: false,
      selected: [],
      dialogIsOpen: false,
      globalSelect: false,
      modal: {
        show: false,
        message: '',
        info: false,
      },
      loadingContracts: [],
    };
  },
  computed: {
    isActiveStatus() {
      return this.status === entityStatuses.ACTIVE;
    },

    displayActions() {
      return this.isActiveStatus && this.$can(UPDATE, CONTRACTS_SUBJECT);
    },

    canDisplayActions() {
      return !this.media.isMobile && this.canUpdateContracts;
    },
    canUpdateContracts() {
      return this.$can(UPDATE, CONTRACTS_SUBJECT);
    },
    entityContractsStatuses() {
      return [
        { id: 0, text: this.$t('contracts.contracts'), name: CONTRACTS },
        { id: 1, text: this.$t('contracts.archive'), name: CONTRACTS_ARCHIVE },
      ];
    },
    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { page: value },
        });
      },
    },
    queryModel: {
      get() {
        return this.query;
      },

      set(value) {
        this.query = value;
      },
    },
    contractsHeaders() {
      return [
        {
          text: this.$t('contracts.clients'),
          value: 'client',
          sortable: true,
          sortValue: 'firstName',
          width: '16%',
        },
        {
          text: this.$t('contracts.type'),
          sortable: true,
          value: 'clientType',
          width: '12%',
        },
        { text: this.$t('contracts.project'), value: 'projectName', sortable: true, width: '13%' },
        { text: this.$t('contracts.building'), value: 'buildingName', sortable: true, width: '14%' },
        { text: this.$t('contracts.unit'), value: 'unitName', sortable: true, width: '14%' },
        { text: this.$t('contracts.periodContracts'), value: 'periodDate', sortable: true, width: '20%' },
        { text: this.$t('contracts.status'), value: 'status', sortable: false, width: '220px' },
      ];
    },

    activeHeader() {
      return this.contractsHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },
    offset() {
      return (this.page - 1) * this.contractsLimit;
    },
    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.contractsHeaders.find(contractHeader => contractHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },
    pageContractsCount() {
      return this.contracts.length;
    },
  },
  watch: {
    queryModel(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$options.throttledSearch();
    },
    orderBy() {
      this.getContracts();
    },
    page() {
      this.selected = [];
      this.globalSelect = false;
    },

    // eslint-disable-next-line
    '$route.query.page': function(newValue) {
      if (newValue) {
        if (this.pageModel === +newValue) {
          return;
        }
        this.page = +newValue || 1;
        this.getContracts();
      }
    },

    // eslint-disable-next-line
    '$route.name': function(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (this.isDataMissing) {
        this.isDataMissing = false;
      }
      if (this.pageModel !== 1) {
        this.pageModel = 1;
        return;
      }
      this.getContracts();
    },
  },

  beforeMount() {
    this.$options.throttledSearch = throttle(() => {
      this.getContracts();
    }, 500);
  },

  mounted() {
    analyticsService.track(CONTRACTS_LIST);
    this.isLoading = true;

    if (this.$route.query.page === undefined)
      this.$router.push({
        path: this.$route.path,
        query: { page: this.pageModel },
      });
    this.getContracts()
      .then(() => {
        if (this.contracts.length === 0 && !this.query) {
          this.isDataMissing = true;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    getLoadingStatus(usageId) {
      const foundUsage = this.loadingContracts.find(id => id === usageId);

      return Boolean(foundUsage);
    },

    archiveUsage(usageId) {
      this.loadingContracts = [...this.loadingContracts, usageId];

      usersService.archiveUsages({ usages: [usageId], isArchived: true }).then(() => {
        this.isLoading = true;

        this.getContracts().finally(() => {
          this.isLoading = false;
          this.loadingContracts = this.loadingContracts.filter(id => id !== usageId);
        });
      });
    },

    async getContracts() {
      if (this.$options.cancelRequestContracts) {
        this.$options.cancelRequestContracts();
        await flushPromises();
      }

      this.selected = [];
      this.tableLoading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelRequestContracts = cancelSource.cancel;

        const data = await usersService.getUsages(
          {
            limit: this.contractsLimit,
            offset: this.offset,
            search: this.query,
            orderBy: this.orderBy,
            status: this.isActiveStatus ? 'active,booked' : 'archived,canceled',
          },
          {
            cancelToken: cancelSource.token,
          }
        );
        const { count, results } = data;

        this.pageCount = Math.ceil(count / this.contractsLimit);

        this.contractsCount = count;

        this.contracts = results.map(contract => this.normalizeData(contract));
      } finally {
        this.tableLoading = false;
        this.$options.cancelRequestContracts = null;
      }
    },
    normalizeData(contract) {
      return {
        id: contract.usageId,
        clientId: contract.client.id,
        client: `${contract.client.firstName} ${contract.client.lastName}`,
        clientType: contract.clientType,
        projectName: contract.project.name,
        projectId: contract.project.id,
        buildingName: contract.building.name,
        buildingId: contract.building.id,
        unitName: contract.unit.name,
        unitId: contract.unit.id,
        status: contract.status,
        periodDate: contract.salesContractDate
          ? contract.salesContractDate
          : `${contract.rentalPeriodStartDate} - ${contract.rentalPeriodEndDate}`,
      };
    },
    selectAllInPage() {
      this.globalSelect = false;
      this.closeSelectDialog();
    },
    selectGlobal() {
      this.globalSelect = true;
      this.closeSelectDialog();
    },
    closeSelectDialog() {
      if (!this.media.isMobile) {
        this.dialogIsOpen = false;
      } else {
        this.closeModal();
      }
    },
    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
    selectAll(props) {
      if (this.media.isMobile) {
        this.selected = this.contracts;

        if (this.pageCount > 1) {
          this.openSelectDialog();
        }

        return;
      }

      if (!props?.value && this.pageCount > 1) {
        this.openSelectDialog();
      }
    },
    openSelectDialog() {
      if (!this.media.isMobile) {
        this.dialogIsOpen = true;
      } else {
        this.modal.message = this.$t('contracts.select_info');
        this.modal.show = true;
      }
    },

    dateDoubleFormat,
    archiveContracts() {
      analyticsService.track(ARCHIVE_CONTRACTS);

      const options = { isArchived: true, usages: this.selected.map(usage => usage.id) };

      const archivePromise = this.globalSelect
        ? usersService.archiveAllUsages(options)
        : usersService.archiveUsages(options);

      archivePromise
        .then(() => {
          this.getContracts();
          this.showModal(this.$t('client.archive_success'));
        })
        .catch(() => {
          this.showModal(this.$t('client.archive_fail'));
        });
    },

    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },
  },
  cancelRequestContracts: null,
};
</script>

<style lang="scss">
.contracts {
  height: 100%;

  &--archive {
    .text-start {
      color: rgba($--black-color-0, 0.5);
    }
  }

  &__missing-data {
    height: 100%;
  }

  &__list {
    min-height: 455px;
  }

  $action-button-width: 36px;

  &__action {
    width: $action-button-width !important;
    height: $action-button-width !important;
    min-width: $action-button-width !important;
    min-height: $action-button-width !important;
    padding: 6px !important;
  }

  &__table-link {
    text-decoration: none;
    color: $--black-color-0 !important;
  }
}
</style>
